import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import logo from './assets/logo.svg';
import { 
  ArrowUp, 
  ArrowDown,
  MoveVertical,
  Gauge,
  Waves,
  Sunrise,
  Sunset,
  ArrowUpToLine,
  ArrowDownToLine,
  ChevronLeft,
  ChevronRight,
  CirclePlus,
  X as RemoveIcon
} from 'lucide-react/dist/esm/icons';
import CountryFlag from './components/CountryFlag';
import { fetchTideData } from './services/tideService';
import EmptyState from './components/EmptyState';

// Replace static imports with dynamic imports for modals and non-critical components
const AddLocationModal = React.lazy(() => import('./components/AddLocationModal'));
const TideRuler = React.lazy(() => import('./components/TideRuler'));

function App() {
  const [timestamp, setTimestamp] = useState(new Date());
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipePosition, setSwipePosition] = useState(0);
  const [, setForceUpdate] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);

  const currentLocation = locations[currentLocationIndex];

  const locationRefs = useRef([]);

  // Timestamp update
  useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Format time as HH:MM:SS
  const formatTime = (date, timezone) => {
    return new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: timezone
    }).format(date);
  };

  // Format date as 'DD Month YYYY'
  const formatDate = (date, timezone) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: timezone
    }).format(date);
  };

  // Get timezone abbreviation
  const getTimezoneAbbr = (timezone) => {
    const date = new Date();
    const timeString = date.toLocaleTimeString('en-US', { 
      timeZoneName: 'short',
      timeZone: timezone 
    });
    return timeString.split(' ')[2];
  };

  // Update the navigation handlers
  const handlePrevLocation = () => {
    setCurrentLocationIndex(prev => 
      prev === 0 ? locations.length - 1 : prev - 1
    );
  };

  const handleNextLocation = () => {
    setCurrentLocationIndex(prev => 
      prev === locations.length - 1 ? 0 : prev + 1
    );
  };

  // Add this function to handle dot clicks
  const handleDotClick = (index) => {
    // Update the current location index
    setCurrentLocationIndex(index);
  };

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.touches[0].clientX);
    setSwipePosition(0);  // Reset swipe position
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
    // Calculate and set the swipe position
    const currentPosition = e.touches[0].clientX;
    const diff = currentPosition - touchStart;
    setSwipePosition(diff);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleNextLocation();
    }
    if (isRightSwipe) {
      handlePrevLocation();
    }
    
    setSwipePosition(0);  // Reset swipe position
  };

  // Add this function to generate wave path based on condition
  const getWavePath = (condition) => {
    const width = 10000;
    const height = 300;
    const segments = 10;
    const segmentWidth = width / segments;
    
    const generateWaveSegments = (yOffset, amplitude) => {
      let path = `M0 ${height/2}`;
      
      for (let i = 0; i < segments; i++) {
        const x1 = i * segmentWidth;
        const x2 = (i + 0.5) * segmentWidth;
        const x3 = (i + 1) * segmentWidth;
        
        path += ` C${x1 + segmentWidth*0.2} ${height/2 + amplitude}, ${x2 - segmentWidth*0.2} ${height/2 - amplitude}, ${x2} ${height/2}`;
        path += ` C${x2 + segmentWidth*0.2} ${height/2 + amplitude}, ${x3 - segmentWidth*0.2} ${height/2 - amplitude}, ${x3} ${height/2}`;
      }
      
      return path;
    };

    // Update amplitudes to match new sea conditions with more distinct differences
    switch(condition.toLowerCase()) {
      case 'very rough':
        return generateWaveSegments(0, height * 0.7);  // 70% height - dramatic waves
      case 'rough':
        return generateWaveSegments(0, height * 0.5);  // 50% height - significant waves
      case 'moderate':
        return generateWaveSegments(0, height * 0.3);  // 30% height - noticeable waves
      case 'calm':
        return generateWaveSegments(0, height * 0.15); // 15% height - gentle movement
      case 'flat':
      default:
        return generateWaveSegments(0, height * 0.05); // 5% height - almost flat
    }
  };

  // Add this utility function
  const calculateTimeUntil = (timeStr) => {
    if (!timeStr || timeStr === "00:00") return "";

    try {
      const now = new Date();
      const [hours, minutes] = timeStr.split(':').map(Number);
      const targetTime = new Date(now);
      targetTime.setHours(hours, minutes, 0);

      // If target time is in the past, add 24 hours
      if (targetTime < now) {
        targetTime.setDate(targetTime.getDate() + 1);
      }

      const diff = targetTime - now;
      const hrs = Math.floor(diff / (1000 * 60 * 60));
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      if (hrs === 0) {
        return `in ${mins}m`;
      } else {
        return `in ${hrs}h ${mins}m`;
      }
    } catch (error) {
      console.error('Error calculating time until:', error);
      return "";
    }
  };

  // Add this effect to update times every second
  useEffect(() => {
    const timer = setInterval(() => {
      setForceUpdate(prev => prev + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Add location management functions
  const handleAddLocation = (newLocation) => {
    // Add new location at the start of the array
    setLocations([newLocation, ...locations]);
    // Reset to show the new location (index 0)
    setCurrentLocationIndex(0);
  };

  const handleRemoveLocation = (index) => {
    const newLocations = [...locations];
    newLocations.splice(index, 1);
    setLocations(newLocations);
    if (currentLocationIndex >= newLocations.length) {
      setCurrentLocationIndex(newLocations.length - 1);
    }
  };

  const updateLocationTideData = async (location) => {
    try {
      const [lat, lon] = location.coordinates;
      const tideData = await fetchTideData(lat, lon);
      
      setLocations(prevLocations => 
        prevLocations.map(loc => 
          loc.name === location.name 
            ? { ...loc, ...tideData }
            : loc
        )
      );
    } catch (error) {
      console.error('Error updating tide data:', error);
    }
  };

  // Add useEffect to update tide data periodically
  useEffect(() => {
    const updateAllLocations = async () => {
      for (const location of locations) {
        await updateLocationTideData(location);
      }
    };
    
    updateAllLocations();
    const interval = setInterval(updateAllLocations, 300000); // Update every 5 minutes
    
    return () => clearInterval(interval);
  }, [locations]);

  const handleKeyDown = (event, index) => {
    if (event.key === 'Tab') {
      event.preventDefault(); // Prevent default tab behavior
      const nextIndex = (index + 1) % locations.length; // Loop back to the start
      locationRefs.current[nextIndex].focus(); // Focus the next location
    }
  };

  return (
    <div className="App">
      <svg className="wave-background" width="100%" height="100%" preserveAspectRatio="none">
        <defs>
          <clipPath id="waveMask">
            <path d={`${getWavePath(currentLocation?.seaCondition || 'Calm')} L${10000} ${100} L${10000} ${1000} L0 ${1000} Z`} />
          </clipPath>
          <linearGradient 
            id="darkGradient" 
            x1="0%" 
            y1="0%" 
            x2="0%" 
            y2="100%" 
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="-23.36%" stopColor="#002E29" stopOpacity="0.1" />
            <stop offset="43.75%" stopColor="#002E29" stopOpacity="1" />
          </linearGradient>
        </defs>

        {/* Single wider wave group */}
        <g>
          <rect
            x="0"
            y="0"
            width="10000"
            height="100%"
            fill="url(#darkGradient)"
            clipPath="url(#waveMask)"
          />
          <path
            d={getWavePath(currentLocation?.seaCondition || 'Calm')}
            stroke="#87FFC0"
            strokeWidth="2"
            fill="none"
            strokeOpacity="0.1"
          />
          <animateTransform
            attributeName="transform"
            type="translate"
            from="0 0"
            to="-5000 0"
            dur="40s"
            repeatCount="indefinite"
          />
        </g>
      </svg>

      <nav className="navbar">
        <div className="navbar-left">
          <img src={logo} alt="Tidespeed" className="navbar-logo" />
          <span className="version-tag">Lite</span>
        </div>

        <div className="nav-controls">
          <header className="app-header">
            
            <button 
              className="add-location-button"
              onClick={() => setIsModalOpen(true)}
            >
              <CirclePlus size={20} />
              Add Location
            </button>
          </header>
          
          <div className="auth-buttons">
            <button className="auth-button">Log in</button>
            <button className="auth-button sign-up">Sign up</button>
          </div>
        </div>
      </nav>

      <main className="main-content">
        {locations.length > 0 ? (
          <>
            {/* Section 1: Location navigation - only show if more than 1 location */}
            {locations.length > 1 && (
              <section className="location-nav">
                <div className="location-controls">
                  <button className="nav-button" onClick={handlePrevLocation}>
                    <ChevronLeft />
                  </button>
                  <div className="location-dots">
                    {locations.map((_, index) => (
                      <span 
                        key={index}
                        className={`dot ${index === currentLocationIndex ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                      />
                    ))}
                  </div>
                  <button className="nav-button" onClick={handleNextLocation}>
                    <ChevronRight />
                  </button>
                </div>
              </section>
            )}

            {/* Dynamic content section */}
            <div className="dynamic-content"
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <div className="locations-container">
                {locations.map((location, index) => (
                  <div 
                    key={index}
                    className="location-content"
                    style={{
                      transform: `translateX(${(index - currentLocationIndex) * 100}%)`,
                      transition: swipePosition ? 'none' : 'transform 0.3s ease-out'
                    }}
                    tabIndex={0}
                    ref={el => locationRefs.current[index] = el}
                    onKeyDown={(event) => handleKeyDown(event, index)}
                  >
                    {/* Section 2: Location info */}
                    <section className="location-header">
                      <h2 className="location-title">
                        {location.name} <CountryFlag countryCode={location.country} />
                      </h2>
                      <button 
                        className="remove-location"
                        onClick={() => handleRemoveLocation(index)}
                      >
                        <RemoveIcon size={20} />
                      </button>
                    </section>

                    {/* Section 3: Main tide and weather info */}
                    <section className="tide-info">
                      {/* 3.1: Current conditions grid */}
                      <div className="current-conditions-grid">
                        <div className="condition-box">
                          <div className="value-group">
                            <div className="icon-wrapper">
                              <MoveVertical />
                            </div>
                            <div className="measurement-value">{location.currentHeight}</div>
                          </div>
                          <div className="measurement-label">Current height</div>
                        </div>
                        <div className="condition-box">
                          <div className="value-group">
                            <div className="icon-wrapper">
                              {location.direction === "Falling" ? <ArrowDown /> : <ArrowUp />}
                            </div>
                            <div className="measurement-value">{location.direction}</div>
                          </div>
                          <div className="measurement-label">Direction</div>
                        </div>
                        <div className="condition-box">
                          <div className="value-group">
                            <div className="icon-wrapper">
                              <Gauge />
                            </div>
                            <div className="measurement-value">{location.cyclePercentage}</div>
                          </div>
                          <div className="measurement-label">Current cycle % height</div>
                        </div>
                        <div className="condition-box">
                          <div className="value-group">
                            <div className="icon-wrapper">
                              <Waves />
                            </div>
                            <div className="measurement-value">{location.seaCondition}</div>
                          </div>
                          <div className="measurement-label">Sea condition</div>
                        </div>
                      </div>

                      {/* 3.2: Next tides */}
                      <div className="info-section">
                        <div className="info-column">
                          <h3 className="section-heading">
                            <div className="icon-wrapper-small">
                              <ArrowUpToLine />
                            </div>
                            Next high tide
                          </h3>
                          <div className="tide-detail-row">
                            <div className="time-value">{location.nextHighTide.time}</div>
                            <div className="measurement-label">
                              {calculateTimeUntil(location.nextHighTide.time)}
                            </div>
                          </div>
                          <div className="tide-detail-row">
                            <div className="time-value">{location.nextHighTide.height}</div>
                            <div className="measurement-label">{location.nextHighTide.comparison}</div>
                          </div>
                        </div>
                        <div className="info-column">
                          <h3 className="section-heading">
                            <div className="icon-wrapper-small">
                              <ArrowDownToLine />
                            </div>
                            Next low tide
                          </h3>
                          <div className="tide-detail-row">
                            <div className="time-value">{location.nextLowTide.time}</div>
                            <div className="measurement-label">
                              {calculateTimeUntil(location.nextLowTide.time)}
                            </div>
                          </div>
                          <div className="tide-detail-row">
                            <div className="time-value">{location.nextLowTide.height}</div>
                            <div className="measurement-label">{location.nextLowTide.comparison}</div>
                          </div>
                        </div>
                      </div>

                      {/* 3.3: Sun times */}
                      <div className="info-section">
                        <div className="info-column">
                          <h3 className="section-heading">
                            <div className="icon-wrapper-small">
                              <Sunrise />
                            </div>
                            Sunrise
                          </h3>
                          <div className="time-value">{location.sunTimes.sunrise}</div>
                        </div>
                        <div className="info-column">
                          <h3 className="section-heading">
                            <div className="icon-wrapper-small">
                              <Sunset />
                            </div>
                            Sunset
                          </h3>
                          <div className="time-value">{location.sunTimes.sunset}</div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
              </div>
            </div>

            {/* Timestamp section */}
            <section className="timestamp">
              <div className="measurement-label">
                {formatTime(timestamp, currentLocation.timezone)} {getTimezoneAbbr(currentLocation.timezone)} | {formatDate(timestamp, currentLocation.timezone)}
              </div>
            </section>

            <React.Suspense fallback={<div>Loading...</div>}>
              <TideRuler 
                currentHeight={currentLocation?.currentHeight}
                nextHighTide={currentLocation?.nextHighTide}
                nextLowTide={currentLocation?.nextLowTide}
              />
            </React.Suspense>
          </>
        ) : (
          <EmptyState onAddLocation={handleAddLocation} />
        )}
      </main>

      <React.Suspense fallback={null}>
        <AddLocationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAddLocation={handleAddLocation}
        />
      </React.Suspense>
    </div>
  );
}

export default App;
