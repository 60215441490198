import React from 'react';
import Flag from 'react-world-flags';

const CountryFlag = ({ countryCode }) => {
  if (!countryCode) {
    return null;
  }

  return (
    <div className="country-flag">
      <Flag 
        code={countryCode.toUpperCase()} 
        height="16"
        fallback={<span className="country-flag placeholder">{countryCode}</span>}
      />
    </div>
  );
};

export default CountryFlag; 