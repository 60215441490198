const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;
const BASE_URL = 'https://api.worldweatheronline.com/premium/v1/marine.ashx';

export const fetchTideData = async (latitude, longitude) => {
  try {
    const today = new Date().toISOString().split('T')[0];
    const response = await fetch(
      `${BASE_URL}?key=${API_KEY}&q=${latitude},${longitude}&format=json&tide=yes&date=${today}&tp=1`
    );
    
    if (!response.ok) {
      throw new Error('Failed to fetch tide data');
    }

    const data = await response.json();
    console.log('Tide API Response:', data);

    if (!data.data?.weather?.[0]) {
      throw new Error('No tide data in response');
    }

    const weather = data.data.weather[0];
    const tides = weather.tides[0].tide_data;
    const hourly = weather.hourly[0];
    const astronomy = weather.astronomy[0];
    
    // Get current tide info
    const currentTide = interpolateCurrentHeight(tides);
    const direction = getDirection(tides);

    // Get next high and low tides
    const { nextHigh, nextLow } = getNextTides(tides.map(tide => ({
      time: tide.tideTime,
      height: `${tide.tideHeight_mt}m`,
      type: tide.tide_type
    })));

    return {
      currentHeight: currentTide.height,
      direction: direction,
      cyclePercentage: calculateCyclePercentage(currentTide, nextHigh, nextLow),
      seaCondition: getSeaCondition(parseFloat(hourly.swellHeight_m) || 0),
      nextHighTide: {
        time: formatTimeInTimezone(nextHigh.time, weather.timezone),
        height: nextHigh.height,
        comparison: getComparison(nextHigh.height)
      },
      nextLowTide: {
        time: formatTimeInTimezone(nextLow.time, weather.timezone),
        height: nextLow.height,
        comparison: getComparison(nextLow.height)
      },
      sunTimes: {
        sunrise: formatTimeInTimezone(astronomy.sunrise, weather.timezone),
        sunset: formatTimeInTimezone(astronomy.sunset, weather.timezone)
      },
      timezone: weather.timezone
    };

  } catch (error) {
    console.error('Error fetching tide data:', error);
    return {
      currentHeight: "0.0m",
      direction: "Unknown",
      cyclePercentage: "0%",
      seaCondition: "Flat",
      nextHighTide: {
        time: "00:00",
        height: "0.0m",
        comparison: "Data unavailable"
      },
      nextLowTide: {
        time: "00:00",
        height: "0.0m",
        comparison: "Data unavailable"
      },
      sunTimes: {
        sunrise: "00:00",
        sunset: "00:00"
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  }
};

// Helper functions
const formatTime = (timeStr) => {
  // If it's a Date object or ISO string
  if (timeStr instanceof Date || timeStr.includes('T')) {
    const date = new Date(timeStr);
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  }

  // If it's already in 12-hour format from API, return as-is
  return timeStr;
};

// eslint-disable-next-line
const getCurrentTideInfo = (tides) => {
  const now = new Date();
  const currentTime = now.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
  
  // Find the current tide by comparing times
  return tides.find((tide, index) => {
    const nextTide = tides[index + 1];
    if (!nextTide) return true;
    
    return currentTime >= tide.time && currentTime < nextTide.time;
  }) || tides[0]; // Default to first tide if none found
};

const getNextTides = (tides) => {
  const now = new Date();
  // eslint-disable-next-line
  const currentTime = now.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });

  // Sort tides by time to ensure correct order
  const sortedTides = [...tides].sort((a, b) => {
    // Convert both times to Date objects for comparison
    const dateA = new Date(`${new Date().toDateString()} ${a.time}`);
    const dateB = new Date(`${new Date().toDateString()} ${b.time}`);
    return dateA - dateB;
  });

  // Find next high and low tides after current time
  const nextHigh = sortedTides.find(tide => 
    tide.type === 'HIGH' && 
    new Date(`${new Date().toDateString()} ${tide.time}`) > new Date()
  );

  const nextLow = sortedTides.find(tide => 
    tide.type === 'LOW' && 
    new Date(`${new Date().toDateString()} ${tide.time}`) > new Date()
  );

  // If no next tide found today, use first tide of appropriate type (tomorrow's tide)
  const firstHigh = sortedTides.find(tide => tide.type === 'HIGH');
  const firstLow = sortedTides.find(tide => tide.type === 'LOW');

  return {
    nextHigh: nextHigh || firstHigh,
    nextLow: nextLow || firstLow
  };
};

const getDirection = (tides) => {
  // Find current time
  const now = new Date();
  const currentTime = now.getTime();

  // Find the surrounding tide events
  let beforeTide = null;
  let afterTide = null;

  for (let i = 0; i < tides.length - 1; i++) {
    const tideTime = new Date(`${new Date().toDateString()} ${tides[i].tideTime}`).getTime();
    const nextTideTime = new Date(`${new Date().toDateString()} ${tides[i + 1].tideTime}`).getTime();
    
    if (currentTime >= tideTime && currentTime < nextTideTime) {
      beforeTide = tides[i];
      afterTide = tides[i + 1];
      break;
    }
  }

  // If we didn't find surrounding tides, use the last and first (crossing midnight)
  if (!beforeTide || !afterTide) {
    beforeTide = tides[tides.length - 1];
    afterTide = tides[0];
  }

  // Determine direction based on the type of tides we're between
  if (beforeTide.tide_type === 'HIGH' && afterTide.tide_type === 'LOW') {
    return 'Falling';
  } else if (beforeTide.tide_type === 'LOW' && afterTide.tide_type === 'HIGH') {
    return 'Rising';
  }

  // If we can't determine direction, use height comparison
  const beforeHeight = parseFloat(beforeTide.tideHeight_mt);
  const afterHeight = parseFloat(afterTide.tideHeight_mt);
  return afterHeight > beforeHeight ? 'Rising' : 'Falling';
};

const calculateCyclePercentage = (current, highTide, lowTide) => {
  // Validate inputs
  if (!current?.height || !highTide?.height || !lowTide?.height) {
    console.warn('Missing tide height data:', { current, highTide, lowTide });
    return "0%";
  }

  const currentHeight = parseFloat(current.height);
  const highHeight = parseFloat(highTide.height);
  const lowHeight = parseFloat(lowTide.height);
  
  const range = Math.abs(highHeight - lowHeight);
  const currentFromLow = Math.abs(currentHeight - lowHeight);
  
  // Ensure percentage is between 0 and 100
  const percentage = Math.min(Math.max((currentFromLow / range) * 100, 0), 100);
  
  return `${Math.round(percentage)}%`;
};

const getComparison = (height) => {
  const heightNum = parseFloat(height);
  if (heightNum > 4) return "Extremely high";
  if (heightNum > 3) return "Much higher than average";
  if (heightNum > 2) return "Higher than average";
  if (heightNum > 1) return "Average height";
  if (heightNum > 0.5) return "Lower than average";
  return "Much lower than average";
};

// Update sea condition thresholds based on swell height
const getSeaCondition = (swellHeight) => {
  console.log('Calculating sea condition for swell height:', swellHeight);
  
  if (swellHeight >= 2.0) return "Very Rough";    // >2m: Very rough seas
  if (swellHeight >= 1.0) return "Rough";         // 1-2m: Rough seas
  if (swellHeight >= 0.5) return "Moderate";      // 0.5-1m: Moderate
  if (swellHeight >= 0.2) return "Calm";          // 0.2-0.5m: Calm
  return "Flat";                                  // <0.2m: Flat
};

const interpolateCurrentHeight = (tides) => {
  const now = new Date();
  const currentTime = now.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });

  // Find the surrounding tide points
  let beforeTide = null;
  let afterTide = null;

  for (let i = 0; i < tides.length - 1; i++) {
    if (currentTime >= tides[i].tideTime && currentTime < tides[i + 1].tideTime) {
      beforeTide = tides[i];
      afterTide = tides[i + 1];
      break;
    }
  }

  // If we didn't find surrounding tides, use the first and last
  if (!beforeTide || !afterTide) {
    beforeTide = tides[tides.length - 1];
    afterTide = tides[0];
  }

  // Since we can't do direct time math with 12-hour format strings,
  // we'll use a simpler progress calculation (50% between tides)
  const progressRatio = 0.5;  // Default to halfway between tides

  // Interpolate height
  const beforeHeight = parseFloat(beforeTide.tideHeight_mt);
  const afterHeight = parseFloat(afterTide.tideHeight_mt);
  const heightDiff = afterHeight - beforeHeight;
  const currentHeight = beforeHeight + (heightDiff * progressRatio);

  return {
    time: formatTime(new Date()),
    height: `${currentHeight.toFixed(2)}m`,
    type: currentHeight > beforeHeight ? 'HIGH' : 'LOW'
  };
};

const formatTimeInTimezone = (timeStr, timezone) => {
  try {
    const date = new Date();
    // Handle AM/PM format from API
    if (timeStr.includes('AM') || timeStr.includes('PM')) {
      const [time, period] = timeStr.split(' ');
      let [hours, minutes] = time.split(':').map(Number);
      if (period === 'PM' && hours !== 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;
      date.setHours(hours, minutes, 0);
    } else {
      // Handle 24-hour format
      const [hours, minutes] = timeStr.split(':').map(Number);
      date.setHours(hours, minutes, 0);
    }
    
    return new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: timezone
    }).format(date);
  } catch (error) {
    console.error('Error formatting time:', timeStr, error);
    return "00:00";
  }
}; 