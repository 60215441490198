import React from 'react';
import LocationSearch from './LocationSearch';

const EmptyState = ({ onAddLocation }) => {
  return (
    <div className="empty-state">
      <h1>Add your first location</h1>
      <p>Search for a location to get started</p>
      <LocationSearch 
        onLocationAdd={onAddLocation} 
        className="empty-state-search"
        autoFocus={true}
      />
    </div>
  );
};

export default EmptyState; 