import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react/dist/esm/icons';
import { searchLocations, getValidCountryCode } from '../services/locationService';
import { fetchTideData } from '../services/tideService';
import CountryFlag from './CountryFlag';

const LocationSearch = ({ onLocationAdd, className = '', autoFocus = false }) => {
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Debounced search
  useEffect(() => {
    if (!searchValue) {
      setSuggestions([]);
      return;
    }

    const timeoutId = setTimeout(async () => {
      try {
        setLoading(true);
        setError(null);
        const results = await searchLocations(searchValue);
        setSuggestions(results);
      } catch (err) {
        setError('Failed to search locations');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  const handleLocationSelect = async (location) => {
    try {
      // Get tide data directly for the selected location
      const tideData = await fetchTideData(
        location.coordinates[0],
        location.coordinates[1]
      );

      // Create new location
      onLocationAdd({
        name: `${location.name}, ${location.region}`,
        country: getValidCountryCode(location.country),
        coordinates: location.coordinates,
        ...tideData
      });

      // Clear search
      setSearchValue('');
      setSuggestions([]);
    } catch (error) {
      setError('Failed to add location');
      console.error("Error adding location:", error);
    }
  };

  return (
    <div className={`location-search ${className}`}>
      <div className="search-input-container">
        <Search size={20} />
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search locations..."
          className="location-search-input"
          autoFocus={autoFocus}
        />
      </div>
      
      {loading && <div className="search-loading">Searching...</div>}
      {error && <div className="search-error">{error}</div>}
      
      {suggestions.length > 0 && (
        <div className="location-search-dropdown">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="location-suggestion"
              onClick={() => handleLocationSelect(suggestion)}
            >
              <div className="suggestion-content">
                <span>{suggestion.name}, {suggestion.region}</span>
                <div className="suggestion-country">
                  <CountryFlag countryCode={getValidCountryCode(suggestion.country)} />
                  <span>{suggestion.country}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationSearch; 